@use "./App.scss" as *;


.About-Top-Container{
    width: 100%;
    // background-image: linear-gradient(to bottom left, $primary, $black);
    background-image: linear-gradient(to right, $bg, $bg, $bg);
    // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    // padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 0 0 60px 0;

}

