@use "./App.scss" as *;


.Service-Container{
    width: 100%;
    // background-image: linear-gradient(to bottom left, $primary, $black);
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 60px 0 0 0;

}

