@use "./App.scss" as *;

.top-nav{
  width: 100%;
}

.navbar-toggler{
  margin-right: -14px;
}

.custom-toggler.navbar-toggler {
  border-color: $primary;
}
/* Setting the stroke to lightblue using rgba values (53, 189, 242, 95) */

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(17, 162, 38)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.header {
  position: sticky;
  transition: 1s;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff !important;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

// .below-nav{
//   padding-top: 175px !important;
// }

.navbar-scrolling {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2) !important;
}

.nav-link {
  color: $primary !important;
  transition: 1s;
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: 500;
}

.nav-link:hover,
.active {
  color: $accent !important;
}

.Logo {
  margin-left: -14px;
  transition: .7s;
  width: 60px;
}

.Logo-scrolling {
  width: 40px !important;
}


.Logo2 {
  margin-top: 2px;
  transition: .7s;
  width: 130px;
}

.Logo-scrolling2 {
  width: 0px !important;
}