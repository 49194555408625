@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800;900&display=swap");

$secondary: #3C2273;
$black: #0D0D0D;
$bg: #F2F2F2;
// $secondary: #168BF2;
$primary: #3C2273;
$accent: #11a226;

body{
  background-color: #fff
}

html,
body,
.App,
.App-home {
  transition: 1s;
}

.App {
  padding: 10px 120px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 2;
  letter-spacing: 1.5px;
  margin: 0;
}

h1,
h2,
h3 {
  font-weight: bold;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.8rem;
}

a {
  text-decoration: none;
}

.txt-White {
  color: #fff;
}

.txt-Accent {
  color: $accent;
}

.txt-black {
  color: $black;
}

.txt-Primary {
  color: $primary;
}

.txt-Secondary {
  color: $secondary;
}

.btn {
  transition: 1s ease-in-out !important;
  font-size: 0.9rem !important;
  font-weight: bold;
  transition: .5s !important;
}

.btn-outline-primary {
  border-color: $primary !important;
  color: $primary !important;
}

.btn-outline-primary:hover {
  background-color: $primary !important;
  color: $bg !important;
}

// SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transition: 1s;
  background: $secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: 1s;
  background: $black;
}

@media (max-width: 1200px) {
  .App {
    padding: 10px 60px !important;
  }

  .App-home {
    padding: 40px 60px !important;
  }
}

@media (max-width: 991px) {
  .Logo2 {
    width: 100px;
  }

  .Logo {
    width: 50px;
  }

  .App {
    padding: 10px 30px !important;
  }

  .App-home {
    padding: 40px 30px !important;
  }

  h1 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  footer {
    text-align: center !important;
  }
}

@media (max-width: 470px) {

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }
}
